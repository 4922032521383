import { V2_MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'
import { useOptionalUser } from '~/utils'
import ReactPlayer from 'react-player'

export const meta: V2_MetaFunction = () => [{ title: 'Engage TV' }]

export default function Index() {
  const user = useOptionalUser()
  return (
    <section className="min-h-[calc(100vh - 132px)]">
      {!user ?
        <div className="container mx-auto px-4">
          <div className="relative mb-0 pt-12 pb-32 md:pt-16 lg:pb-48">
            <div className="mx-auto mb-16 max-w-2xl text-center">
              <h2 className="font-heading mb-4 text-4xl font-bold leading-tight text-primary drop-shadow-md md:text-4xl md:leading-tight lg:text-6xl lg:leading-tight">
                Make your brand shine with Engage TV.
              </h2>
              <p className="mb-8 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                Welcome to Engage TV Creative Builder for all your video needs.
              </p>
              <p className="mb-8 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                Ready to begin? Simply sign up using our form.
              </p>
              <p className="mb-8 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                Using our creative builder tools, you will be able to produce professional videos for Streaming TV, YouTube and Social in as little as 10 minutes.
              </p>
              <p className="py-8 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                Let’s get started!
              </p>
              <h2 className="font-heading mb-4 text-4xl font-bold leading-tight text-primary drop-shadow-md md:text-4xl md:leading-tight lg:text-6xl lg:leading-tight">
                See How Easy it is
              </h2>
              <div className="mt-10 mb-2 w-full max-w-full h-[378px]">
                <iframe width="100%" height="100%" src="https://player.vimeo.com/video/985283072" title="Engage TV Ad Manager" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <div>
                <div>
                  <Link
                    className="mb-3 block rounded bg-secondary px-5 py-3 text-center text-sm font-semibold text-white transition duration-200 hover:bg-red-700 hover:shadow-lg md:mr-3 md:mb-0 md:inline-block"
                    to="/signup"
                  >
                    Create a free account
                  </Link>
                </div>
              </div>
            </div>
            <div className="relative mx-auto mt-12 max-w-3xl">
              <img
                className="relative top-0 left-0 mx-auto h-80 w-full rounded-xl object-cover md:h-96"
                src="assets/illustrations/engineering-team.svg"
                alt=""
              />
            </div>
          </div>
        </div> :
        <div className="container mx-auto px-4">
          <div className="relative mb-0 pt-12 pb-4 md:pb-32 md:pt-16 lg:pb-48">
            <div className="mx-auto mb-4 md:mb-16 max-w-2xl text-left">
              <h2 className="font-heading mb-8 text-center text-4xl font-bold leading-tight text-primary drop-shadow-md md:text-4xl md:leading-tight lg:text-6xl lg:leading-tight">
                Welcome
              </h2>
              <p className="mb-2 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                Thanks for signing up and welcome to Engage TV Site!
              </p>
              <p className="mb-8 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                Let’s <Link className="underline" to="/campaigns">get started!</Link>
              </p>
              <p className="mb-8 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                Using our creative builder tools, you will be able to produce professional videos for Streaming TV, YouTube and Social in as little as 10 minutes.
              </p>
              <div className="mt-10 mb-2 w-full max-w-full h-[378px]">
                <iframe width="100%" height="100%" src="https://player.vimeo.com/video/985283072" title="Engage TV Ad Manager" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
              <div>
                <div className='text-center'>
                  <Link
                    className="mb-3 text-center block rounded bg-secondary px-5 py-3 text-center text-sm font-semibold text-white transition duration-200 hover:bg-red-700 hover:shadow-lg md:mr-3 md:mb-0 md:inline-block"
                    to="/campaigns"
                  >
                    View creatives for {user.email}
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      }
    </section>
  )
}
